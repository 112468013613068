<template>
    <div class="container">
    	<builder-content model="page" @on-error="onError" @on-load="onLoad" />
    </div>
</template>

<script setup lang="ts">
import BuilderContent from "~/components/builderContent.vue";

const onLoad = (pageData) => {
    useSeoMeta({
        title: pageData?.data?.title,
        description: pageData?.data?.description
    });
}

const onError = () => {
    if (import.meta.server) {
        showError({ statusCode: 404, statusMessage: 'Page Not Found' });
    }
}

</script>